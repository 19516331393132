import { Icon } from '@makerdao/dai-ui-icons'
import BigNumber from 'bignumber.js'
import { getTokenGuarded } from 'blockchain/tokensMetadata'
import { GenericTokenIcon } from 'components/GenericTokenIcon'
import { formatCryptoBalance } from 'helpers/formatters/format'
import { zero } from 'helpers/zero'
import React from 'react'
import { Box, Flex, Heading, Image, Text } from 'theme-ui'

interface SimulateTitleProps {
  token: string
  tokenSymbol?: string
  depositAmount?: BigNumber
  amount?: number
  apy: number
}

export function SimulateTitle({
  token,
  tokenSymbol,
  depositAmount,
  amount,
  apy,
}: SimulateTitleProps) {
  const tokenConfig = getTokenGuarded(token)

  return (
    <Flex
      sx={{
        flexDirection: ['column', null, 'row'],
        px: [3, null, '24px'],
        py: '24px',
        borderBottom: 'lightMuted',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }}
    >
      <Flex sx={{ alignItems: 'center' }}>
        {token === 'dLP' && (
          <Image
            src="/static/icons/tokens/ETH.svg"
            sx={{
              marginRight: '-24px',
              zIndex: 2,
              width: '40px',
              '@media screen and (max-width: 500px)': {
                width: '30px',
                marginRight: '-18px',
              },
            }}
          />
        )}
        <Image
          src={`/static/icons/tokens/${token === 'ETH' ? 'stETH' : token}.svg`}
          sx={{
            marginRight: '8px',
            width: '40px',
            '@media screen and (max-width: 500px)': {
              width: '30px',
            },
          }}
        />
        <Box>
          <Heading
            as="h3"
            variant="heading3"
            sx={{
              fontWeight: 'normal',
              fontSize: '32px',
              color: 'primary100',
              whiteSpace: 'nowrap',
              '@media screen and (max-width: 500px)': {
                fontSize: '21px',
              },
            }}
          >
            ${parseFloat((amount as number).toFixed(2)).toLocaleString('en-US')}
            {" " + (token === 'ETH' ? 'stETH' : token)}
          </Heading>
          {/* <Text
          variant="paragraph3"
          color="neutral80"
          sx={{
            fontWeight: 'semiBold',
          }}
        >
          {`In this position`}
        </Text> */}
        </Box>
      </Flex>
      {token === 'dLP' && (
        <Box
          sx={{
            textAlign: 'right',
            '@media screen and (max-width: 500px)': {
              marginTop: '8px',
            },
          }}
        >
          <Text sx={{ color: '#596F78', fontSize: '12px', lineHeight: '20px' }}>
            APR
          </Text>
          <Text
            style={{
              color: 'transparent',
              background: 'linear-gradient(95deg, #F89791 2.31%, #469FF2 97.88%)',
              WebkitBackgroundClip: 'text',
              marginTop: '-10px',
            }}
            sx={{
              '@media screen and (max-width: 500px)': {
                fontSize: '24px',
                marginBottom: '-20px'
              },
            }}
            variant="headerApy"
          >
            {apy ? apy.toFixed(2) : '--'}%<span style={{ fontSize: '24px', fontWeight: 500 }}>+</span>
          </Text>
        </Box>
      )}
    </Flex>
  )
}
