import { AppLink } from 'components/Links'
import { MyPositionsOrb } from 'components/navigation/content/MyPositionsOrb'
import { NotificationsOrb } from 'components/navigation/content/NotificationsOrb'
import { SwapWidgetOrb } from 'components/navigation/content/SwapWidgetOrb'
import TokenToClaim from 'components/navigation/content/TokenToClaim'
import { WalletOrb } from 'components/navigation/content/WalletOrb'
import { WalletPanelMobile } from 'components/navigation/content/WalletPanelMobile'
import { navigationBreakpoints } from 'components/navigation/Navigation'
import { NavigationNetworkSwitcherOrb } from 'components/navigation/NavigationNetworkSwitcher'
import { ConnectButton } from 'features/web3OnBoard'
import { useFeatureToggle } from 'helpers/useFeatureToggle'
import { useRouter } from 'next/router'
import React from 'react'
import { Flex, Image } from 'theme-ui'
import { useMediaQuery } from 'usehooks-ts'

interface NavigationActionsControllerProps {
  isConnected: boolean
}

export function NavigationActionsController({ isConnected }: NavigationActionsControllerProps) {
  const isViewBelowXl = useMediaQuery(`(max-width: ${navigationBreakpoints[3] - 1}px)`)
  const isViewBelowL = useMediaQuery(`(max-width: ${navigationBreakpoints[2] - 1}px)`)
  const isViewBelowM = useMediaQuery(`(max-width: ${navigationBreakpoints[1] - 1}px)`)

  const useNetworkSwitcher = useFeatureToggle('UseNetworkSwitcher')

  const { pathname } = useRouter()

  // const auditLink = 'https://match-finance.gitbook.io/whitepaper/security'

  return (
    <>
      {isConnected ? (
        <>
          {/* {isViewBelowXl && <SwapWidgetOrb />}
          {isViewBelowXl && <MyPositionsOrb />} */}
          {/* <NotificationsOrb /> */}
          {/* <Flex sx={{ alignItems: 'center', marginRight: isViewBelowM ? '0' : '12px' }}>
            <AppLink sx={{ color: '#17344F' }} href={auditLink}>
              Security Audit
              {!isViewBelowM && (
                <Image
                  src="/static/icons/check.svg"
                  sx={{ marginLeft: '4px', width: '20px', marginBottom: '-4px' }}
                />
              )}
            </AppLink>
          </Flex> */}
          {/* {pathname !== '/tge/rewards' && <TokenToClaim />} */}
          {useNetworkSwitcher && <NavigationNetworkSwitcherOrb />}
          {isViewBelowM ? <WalletPanelMobile /> : <WalletOrb />}
        </>
      ) : (
        <>
          {/* <Flex sx={{ alignItems: 'center', marginRight: isViewBelowM ? '0' : '12px' }}>
            <AppLink sx={{ color: '#17344F' }} href={auditLink}>
              Security Audit
              {!isViewBelowM && (
                <Image
                  src="/static/icons/check.svg"
                  sx={{ marginLeft: '4px', width: '20px', marginBottom: '-4px' }}
                />
              )}
            </AppLink>
          </Flex> */}
          {useNetworkSwitcher && <NavigationNetworkSwitcherOrb />}
          {/* {!isViewBelowL && <ConnectButton />} */}
          <ConnectButton />
        </>
      )}
    </>
  )
}
