import axios from 'axios'
import { AppLink } from 'components/Links'
import { useWalletManagement } from 'features/web3OnBoard'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { Box, Flex, Image, Text } from 'theme-ui'

import GlobalMask from './GlobalMask'

const LittleLogo = (
  <Image
    sx={{ position: 'relative', left: '1px', top: '2px' }}
    src="/static/icons/little_icon.svg"
  />
)

export default function () {
  const { wallet } = useWalletManagement()
  const [popup, setPopup] = useState<any>(null)
  const popup1 = (
    <GlobalMask bg="white">
      <Box
        sx={{
          width: '540px',
          // height: '198px',
          borderRadius: '20px',
          backgroundImage: 'url(/static/img/twitter_bg.png)',
          backgroundColor: '#7B7EFF',
          padding: '37px 30px 30px',
          '@media screen and (max-width: 500px)': {
            transform: 'scale(0.8)',
            minWidth: '540px',
          },
        }}
      >
        <Box
          sx={{
            fontSize: '32px',
            fontWeight: 700,
            textAlign: 'center',
            color: '#fff',
            lineHeight: 'normal',
            textShadow:
              '0px 0px 0px rgba(0, 0, 0, 0.10), 4px 2.5px 9.5px rgba(0, 0, 0, 0.10), 15px 9px 17.5px rgba(0, 0, 0, 0.09), 34px 20.5px 23.5px rgba(0, 0, 0, 0.05), 60px 36.5px 28px rgba(0, 0, 0, 0.01), 94px 56.5px 30.5px rgba(0, 0, 0, 0.00)',
          }}
        >
          <Text>Stay c{LittleLogo}nnected</Text>
          <Text>
            f{LittleLogo}ll{LittleLogo}w us {LittleLogo}n{' '}
            <span style={{ color: '#000279' }}>Twitter</span> t{LittleLogo}day!
          </Text>
        </Box>
        <Flex
          sx={{
            justifyContent: 'center',
            mt: '20px',
            fontSize: '12px',
            fontWeight: 600,
          }}
        >
          <Flex
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              color: '#fff',
              width: '108px',
              height: '33px',
              border: '1px solid #FFF',
              borderRadius: '32px',
              cursor: 'pointer',
            }}
            onClick={() => setPopup(null)}
          >
            Cancel
          </Flex>
          <AppLink
            sx={{
              ml: '20px',
              fontSize: '12px',
            }}
            href="https://twitter.com/matchfinance?s=21"
          >
            <Flex
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                color: '#1C1C1C',
                width: '108px',
                height: '33px',
                background: '#fff',
                borderRadius: '32px',
                cursor: 'pointer',
                img: {
                  content: 'url(/static/icons/X.svg)',
                },
                '&:hover': {
                  background: 'linear-gradient(137deg, #B9C2F4 13.99%, #5065E4 85.87%)',
                  color: '#fff',
                  img: {
                    content: 'url(/static/icons/X_hover.svg)',
                  },
                },
              }}
            >
              <Image mr="5px" />
              Follow us
            </Flex>
          </AppLink>
        </Flex>
      </Box>
    </GlobalMask>
  )

  const {
    query: { channel },
    pathname,
    replace,
  } = useRouter()
  useEffect(() => {
    console.log('channel', channel)
    if (wallet?.address && channel) {
      void axios
        .post<any>('https://api.matchfinance.io/user-data/record', {
          publicAddress: wallet.address,
          trafficSource: parseInt(channel as string),
        })
        .then(({ data }) => {
          console.log(data)
          if (data.message === 'success') {
            void replace(pathname)
          }
        })
    }
  }, [wallet?.address, channel])
  // useEffect(() => {
  //   if (channel) setPopup(popup1)
  // }, [channel])
  return popup
}
