export enum NetworkHexIds {
  MAINNET = '0x1',
  GOERLI = '0x5',
  DEFAULTFORK = '0x859',
  ARBITRUMMAINNET = '0xa4b1',
  ARBITRUMGOERLI = '0x66eed',
  POLYGONMAINNET = '0x89',
  POLYGONMUMBAI = '0x13881',
  OPTIMISMMAINNET = '0xa',
  OPTIMISMGOERLI = '0x1a4',
  EMPTYNET = '0x0',
  SEPOLIA = '0xaa36a7'
}
