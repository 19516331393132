import { AppLink } from 'components/Links'
import { NavigationActions } from 'components/navigation/NavigationActions'
import {
  NavigationBranding,
  NavigationBrandingPill,
} from 'components/navigation/NavigationBranding'
import { NavigationMenu } from 'components/navigation/NavigationMenu'
import { NavigationMenuPanelLinkType } from 'components/navigation/NavigationMenuLink'
import { NavigationOrb } from 'components/navigation/NavigationMenuOrb'
import { NavigationMenuPanelType } from 'components/navigation/NavigationMenuPanel'
import { NavigationMobileMenu } from 'components/navigation/NavigationMobileMenu'
import { INTERNAL_LINKS } from 'helpers/applicationLinks'
import { useOutsideElementClickHandler } from 'helpers/useOutsideElementClickHandler'
import { useToggle } from 'helpers/useToggle'
import { useRouter } from 'next/router'
import React, { ReactNode } from 'react'
import { Box, Container, Flex, Image, Text, ThemeProvider } from 'theme-ui'
import { useMediaQuery } from 'usehooks-ts'

interface NavigationProps {
  actions?: ReactNode
  brandingLink?: string
  links?: NavigationMenuPanelLinkType[]
  panels?: NavigationMenuPanelType[]
  pill?: NavigationBrandingPill
}

export const navigationBreakpoints = [531, 744, 1025, 1279]
export const navigationBreakpointsWithPixels = navigationBreakpoints.map(
  (breakpoint) => `${breakpoint}px`,
)

const airdropNavActiveStyle = {
  background: 'linear-gradient(137deg, #B9C2F4 13.99%, #5065E4 85.87%)',
  color: '#fff',
  border: 'none',
  img: {
    content: 'url("/static/icons/airdrop.svg")',
  },
}
const airdropNavDefaultStyle = {
  border: '1px solid #5065E4',
  color: '#5065E4',
  img: {
    content: 'url("/static/icons/airdrop_default.svg")',
  },
}
const tgeNavActiveStyle = {
  background: 'linear-gradient(137deg, #B9C2F4 13.99%, #5065E4 85.87%)',
  color: '#fff',
  border: 'none',
  img: {
    content: 'url("/static/icons/tge.svg")',
  },
}
const tgeNavDefaultStyle = {
  border: '1px solid #5065E4',
  color: '#5065E4',
  img: {
    content: 'url("/static/icons/tge_default.svg")',
  },
}
const matchNavActiveStyle = {
  background: 'linear-gradient(137deg, #B9C2F4 13.99%, #5065E4 85.87%)',
  WebkitBackgroundClip: 'text',
  color: 'transparent',
}

const navButtonStyle = {
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: 500,
  width: '96px',
  height: '32px',
  borderRadius: '16px',
  '@media screen and (max-width: 500px)': {
    width: '32px',
  },
}

export function Navigation({
  actions,
  brandingLink = INTERNAL_LINKS.homepage,
  links,
  panels,
  pill,
}: NavigationProps) {
  const { pathname } = useRouter()
  const [isMobileMenuOpen, toggleIsMobileMenuOpen, setIsMobileMenuOpen] = useToggle(false)
  const ref = useOutsideElementClickHandler(() => setIsMobileMenuOpen(false))
  const isViewBelowL = useMediaQuery(`(max-width: ${navigationBreakpoints[2] - 1}px)`)

  return (
    <ThemeProvider theme={{ breakpoints: navigationBreakpointsWithPixels }}>
      <Container
        as="header"
        variant="navigation"
        sx={{
          position: 'relative',
          display: 'flex',
          gridTemplateColumns: ['auto auto', null, null, 'auto auto auto', '30% 40% 30%'],
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: '24px',
          mb: '32px',
          zIndex: 3,
          '@media screen and (max-width: 460px)': {
            mb: '24px',
          },
        }}
      >
        <NavigationBranding link={brandingLink} pill={pill} />
        {/* {!isViewBelowL && <NavigationMenu links={links} panels={panels} />} */}

        <NavigationActions>
          {actions}
          {/* {isViewBelowL && (
            <Box ref={ref}>
              <NavigationOrb icon="menu" iconSize={20} onClick={toggleIsMobileMenuOpen} />
              <NavigationMobileMenu
                close={() => {
                  setIsMobileMenuOpen(false)
                }}
                isOpen={isMobileMenuOpen}
                links={links}
                panels={panels}
              />
            </Box>
          )} */}
        </NavigationActions>
      </Container>
    </ThemeProvider>
  )
}
