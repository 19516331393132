import { WithArrow } from 'components/WithArrow'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'
import { Button, Image } from 'theme-ui'
import { useMediaQuery } from 'usehooks-ts'

import OkxWallet from './okx-wallet-select'
import { useConnection } from './useConnection'

export function ConnectButton() {
  const { t } = useTranslation()
  const { connecting, connect, settingChain } = useConnection()
  const isSmallScreen = useMediaQuery('(max-width: 700px)')
  const [isMobile, setIsMobile] = useState(false)
  const [isOkxApp, setIsOkxApp] = useState(false)
  useEffect(() => {
    const ua = navigator.userAgent
    const isIOS = /iphone|ipad|ipod|ios/i.test(ua)
    const isAndroid = /android|XiaoMi|MiuiBrowser/i.test(ua)
    const isMobile = isIOS || isAndroid
    setIsMobile(isMobile)
    const isOkxApp = /OKApp/i.test(navigator.userAgent)
    setIsOkxApp(isOkxApp)
    if (isOkxApp) {
      let timeout: any = setTimeout(() => {
        connect()
        timeout = null
      }, 500)
      return () => timeout && clearInterval(timeout)
    }
  }, [])
  const [okxConnecting, setOkxConnecting] = useState(false)

  const connectOthers = () => {
    setOkxConnecting(false)
    connect()
  }

  return (
    <div>
      <Button
        variant="buttons.secondary"
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexShrink: 0,
          height: '100%',
          p: 0,
          textDecoration: 'none',
          bg: 'neutral10',
          boxShadow: 'buttonMenu',
          '@media screen and (max-width: 700px)': {
            padding: '10px',
            width: '40px',
          },
        }}
        disabled={connecting || settingChain}
        onClick={() => (isMobile && !isOkxApp ? setOkxConnecting(true) : connect())}
      >
        {isSmallScreen ? (
          <Image src="/static/icons/wallet.svg" sx={{ width: '20px' }} />
        ) : (
          <WithArrow
            sx={{
              py: 2,
              pl: 4,
              pr: '40px',
              fontSize: '16px',
            }}
          >
            Connect Wallet
          </WithArrow>
        )}
      </Button>
      {okxConnecting && (
        <OkxWallet onClose={() => setOkxConnecting(false)} onOthers={connectOthers} />
      )}
    </div>
  )
}
