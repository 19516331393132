import { ADDRESSES } from '@oasisdex/addresses'
import * as erc20 from 'blockchain/abi/erc20.json'
import * as guniToken from 'blockchain/abi/guni-token.json'
import { contractDesc } from 'blockchain/networks'
import { ContractDesc } from 'features/web3Context'

export const AAVE_V3_POOL_GENESIS_OPTIMISM_MAINNET = 4365693
export const ACCOUNT_GUARD_GENESIS_OPTIMISM_MAINNET = 84955123
export const ACCOUNT_FACTORY_GENESIS_OPTIMISM_MAINNET = 84955175

const { optimism } = ADDRESSES

export const tokensOptimism = {
  CBETH: contractDesc(erc20, optimism.common.CBETH),
  CRVV1ETHSTETH: contractDesc(guniToken, optimism.common.CRVV1ETHSTETH),
  DAI: contractDesc(erc20, optimism.common.DAI),
  ETH: contractDesc(erc20, optimism.common.WETH),
  FRAX: contractDesc(erc20, optimism.common.FRAX),
  GHO: contractDesc(erc20, optimism.common.GHO),
  GUNIV3DAIUSDC1: contractDesc(guniToken, optimism.common.GUNIV3DAIUSDC1),
  GUNIV3DAIUSDC2: contractDesc(guniToken, optimism.common.GUNIV3DAIUSDC2),
  LDO: contractDesc(erc20, optimism.common.LDO),
  LUSD: contractDesc(erc20, optimism.common.LUSD),
  MKR: contractDesc(erc20, optimism.maker.common.McdGov),
  RETH: contractDesc(erc20, optimism.common.RETH),
  RENBTC: contractDesc(erc20, optimism.common.RENBTC),
  SDAI: contractDesc(erc20, optimism.common.SDAI),
  STETH: contractDesc(erc20, optimism.common.STETH),
  TBTC: contractDesc(erc20, optimism.common.TBTC),
  USDT: contractDesc(erc20, optimism.common.USDT),
  USDC: contractDesc(erc20, optimism.common.USDC),
  USDP: contractDesc(erc20, optimism.common.PAXUSD),
  WBTC: contractDesc(erc20, optimism.common.WBTC),
  WETH: contractDesc(erc20, optimism.common.WETH),
  WLD: contractDesc(erc20, optimism.common.WLD),
  WSTETH: contractDesc(erc20, optimism.common.WSTETH),
  YIELDBTC: contractDesc(erc20, optimism.common.YIELDBTC),
  YIELDETH: contractDesc(erc20, optimism.common.YIELDETH),
} as Record<string, ContractDesc>
