import { Icon } from '@makerdao/dai-ui-icons'
import { AppLink } from 'components/Links'
import dayjs from 'dayjs'
import { EXTERNAL_LINKS, INTERNAL_LINKS } from 'helpers/applicationLinks'
import { staticFilesRuntimeUrl } from 'helpers/staticPaths'
import { useTranslation } from 'next-i18next'
import getConfig from 'next/config'
import React from 'react'
import { Box, Card, Container, Flex, Grid, Image, Link, Text } from 'theme-ui'
import { useMediaQuery } from 'usehooks-ts'

const {
  publicRuntimeConfig: { buildHash, buildDate, showBuildInfo },
} = getConfig()

export function TemporaryFooter() {
  const commit = buildHash.substring(0, 10)
  const date = dayjs(buildDate).format('DD.MM.YYYY HH:MM')
  console.debug(`Build commit: ${commit} Build date: ${date}`)
  return (
    showBuildInfo && (
      <Container sx={{ maxWidth: '898px' }}>
        <Grid sx={{ color: 'text', fontSize: 2 }} columns={2}>
          <Text>
            Commit:{' '}
            <Link
              href={`${EXTERNAL_LINKS.GITHUB}/commit/${buildHash}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {commit}
            </Link>
          </Text>
          <Text>Build Date: {date}</Text>
        </Grid>
      </Container>
    )
  )
}

function SocialWithLogo() {
  return (
    <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <Image
        src={'/static/img/logos/match_logo.svg'}
        sx={{ width: '300px', position: 'relative', top: '-2px' }}
      />
      <Flex sx={{ alignItems: 'center', a: { fontSize: '0px' }, my: 2 }}>
        <AppLink href="https://match-finance.gitbook.io/whitepaper/">
          <Image src="/static/icons/book.svg" />
        </AppLink>
        <AppLink href="https://twitter.com/matchfinance?s=21" sx={{ ml: 3 }}>
          <Icon name="twitter" size="auto" width="18px" height="16px" />
        </AppLink>
        <AppLink href={EXTERNAL_LINKS.DISCORD} sx={{ mx: 3 }}>
          <Icon name="discord" size="auto" width="20px" height="23px" />
        </AppLink>
        <AppLink href={EXTERNAL_LINKS.GITHUB}>
          <Icon name="github" size="auto" width="21px" />
        </AppLink>
      </Flex>
    </Flex>
  )
}
export function FooterContent() {
  const isViewBelowM = useMediaQuery('(max-width: 500px)')
  return (
    <Flex>
      <Box>
        <Image src={'/static/img/logos/match_logo.svg'} sx={{ width: '300px' }} />
        <Text
          sx={{
            fontSize: '12px',
            lineHeight: '24px',
            mt: '6px',
            '@media screen and (max-width: 500px)': {
              display: 'none',
            },
          }}
        >
          Yield Aggregator for Lybra Finance and boost your LSD yield
        </Text>
      </Box>
      {/* <Box
        sx={{
          ml: '130px',
          fontWeight: 600,
          '@media screen and (max-width: 500px)': { ml: '-80px' },
        }}
      >
        <Text
          sx={{
            color: '#000',
            fontSize: '16px',
            lineHeight: '24px',
            '@media screen and (max-width: 500px)': { fontSize: '14px' },
          }}
        >
          Quick Links
        </Text>
        <Flex
          sx={{
            mt: '22px',
            '@media screen and (max-width: 500px)': { mt: '12px' },
          }}
        >
          <Box sx={{ width: '200px', '@media screen and (max-width: 500px)': { width: '60px' } }}>
            <AppLink
              sx={{
                color: '#17344F',
                '@media screen and (max-width: 500px)': { fontSize: '12px' },
              }}
              href="https://twitter.com/matchfinance?s=21"
            >
              Twitter
            </AppLink>
          </Box>
          <AppLink
            sx={{ color: '#17344F', '@media screen and (max-width: 500px)': { fontSize: '12px' } }}
            href="https://match-finance.gitbook.io/whitepaper/"
          >
            Docs
          </AppLink>
        </Flex>
        <Flex sx={{ mt: '16px' }}>
          <Box sx={{ width: '200px', '@media screen and (max-width: 500px)': { width: '70px' } }}>
            <AppLink
              sx={{
                color: '#17344F',
                '@media screen and (max-width: 500px)': { fontSize: '12px' },
              }}
              href={EXTERNAL_LINKS.DISCORD}
            >
              Discord
            </AppLink>
          </Box>
          <AppLink
            sx={{ color: '#17344F', '@media screen and (max-width: 500px)': { fontSize: '12px' } }}
            href={EXTERNAL_LINKS.GITHUB}
          >
            Github
          </AppLink>
        </Flex>
      </Box> */}
      <Box
        sx={{
          ml: '500px',
          fontWeight: 600,
          '@media screen and (max-width: 500px)': { ml: '80px', pb: '30px' },
        }}
      >
        <Text
          sx={{
            color: '#000',
            fontSize: '16px',
            lineHeight: '24px',
            '@media screen and (max-width: 500px)': { fontSize: '14px' },
          }}
        >
          Audited by
        </Text>
        <AppLink sx={{ ml: '-11px' }} href="https://match-finance.gitbook.io/whitepaper/security">
          <Image sx={{ mt: '26px' }} src="/static/img/beosin.png" />
        </AppLink>
        <AppLink href="https://match-finance.gitbook.io/whitepaper/security">
          <Image sx={{ mt: '20px', display: 'block' }} src="/static/img/slowmist.png" />
        </AppLink>
      </Box>
    </Flex>
  )
}

export function Footer() {
  const isViewBelowM = useMediaQuery('(max-width: 500px)')

  return (
    <Box as="footer" sx={{ position: 'relative' }}>
      <Container sx={{ maxWidth: '1200px', mb: 5, pb: 0, pt: 2 }}>
        <Box>
          <FooterContent />
        </Box>
      </Container>
    </Box>
  )
}
