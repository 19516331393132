import { Flex } from 'theme-ui'

export default function GlobalMask({ bg, children, onClick }: any) {
  return (
    <Flex
      onClick={onClick}
      sx={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: '0',
        left: '0',
        zIndex: '100',
        justifyContent: 'center',
        alignItems: 'center',
        background: bg === 'white' ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.50)',
        backdropFilter: 'blur(10px)',
      }}
    >
      {children}
    </Flex>
  )
}
