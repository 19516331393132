import React, { useEffect, useState } from 'react'
import { Box, Flex, Image, Text } from 'theme-ui'

export default function OkxWallet({ onClose, onOthers }: any) {
  const okxConnect = () => {
    const ua = navigator.userAgent
    const isOKApp = /OKApp/i.test(ua)
    if (!isOKApp) {
      // open dapp in OKX App
      // replace 'https://opensea.io' with your dapp url
      window.open(`okx://wallet/dapp/details?dappUrl=${window.location.origin}`)
    }
  }
  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100vw',
          height: '100vh',
          background: 'rgba(0,0,0,0.6)',
          zIndex: 100,
        }}
      >
        <Flex
          sx={{
            position: 'absolute',
            bottom: '0',
            left: '0',
            background: '#fff',
            width: '100%',
            height: '563px',
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
            flexDirection: 'column',
            alignItems: 'stretch',
          }}
        >
          <Text
            sx={{
              padding: '24px 0 22px 36px',
              color: 'rgba(0,0,0,0.8)',
              fontSize: '18px',
              borderBottom: '1px solid #E7EBEE',
            }}
          >
            Connect your wallet
          </Text>
          <Image
            src="/static/icons/close_wallet_connect.svg"
            sx={{
              position: 'absolute',
              width: '34px',
              height: '34px',
              top: '9px',
              right: '9px',
            }}
            onClick={onClose}
          />

          <Flex
            sx={{
              flexGrow: 1,
              justifyContent: 'center',
              pt: '90px'
            }}
          >
            <Flex
              sx={{
                width: '170px',
                height: '170px',
                borderRadius: '9px',
                border: '1px solid #7D7C8A',
                flexDirection: 'column',
                alignItems: 'center',
                pt: '36px',
              }}
              onClick={okxConnect}
            >
              <Image src="/static/icons/okx_wallet.svg" sx={{ width: '72px' }} />
              <Text sx={{ mt: '13.5px', fontSize: '18px', color: '#000' }}>OKX Wallet</Text>
            </Flex>
            <Flex
              sx={{
                width: '170px',
                height: '170px',
                borderRadius: '9px',
                border: '1px solid #7D7C8A',
                flexDirection: 'column',
                alignItems: 'center',
                pt: '36px',
                ml: '27px',
              }}
              onClick={onOthers}
            >
              <Image src="/static/icons/other_wallet.svg" sx={{ width: '72px' }} />
              <Text sx={{ mt: '13.5px', fontSize: '18px', color: '#000' }}>Others</Text>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </>
  )
}
