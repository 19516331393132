import { Navigation, navigationBreakpoints } from 'components/navigation/Navigation'
import { SwapWidgetShowHide } from 'components/swapWidget/SwapWidgetShowHide'
import { NavigationActionsController } from 'features/navigation/controls/NavigationActionsController'
import { useAccount } from 'helpers/useAccount'
import React from 'react'

export function NavigationController() {
  const { isConnected, walletAddress } = useAccount()

  return (
    <>
      <Navigation
        links={[]}
        actions={<NavigationActionsController isConnected={isConnected} />}
      />
      <SwapWidgetShowHide />
    </>
  )
}
