import axios from 'axios'
import { AppLink } from 'components/Links'
import { useWalletManagement } from 'features/web3OnBoard'
// import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { Box, Button, Flex, Image, Text } from 'theme-ui'

import GlobalMask from './GlobalMask'

export default function ({ onHasSigned }: any) {
  const { wallet, disconnect } = useWalletManagement()
  const [show, setShow] = useState<boolean>(false)
  const [showMore, setShowMore] = useState(false)
  const [agreed, setAgreed] = useState(false)

  const getUserInfo = () => {
    if (!wallet?.address) return setShow(false)
    const token = localStorage.getItem(`token-${wallet.address}`)
    if (!token) return
    axios('https://api.matchfinance.io/users/info', {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(({ data }: any) => {
        // console.log(data)
        onHasSigned(data.data.has_signed)
        data.data.has_signed || setShow(true)
      })
      .catch((e) => {
        // console.log(e, e.statusCode, e.response)
        if (e.response && e.response.status === 401) {
          localStorage.setItem(`token-${wallet?.address}`, '')
          ;(window as any).onResign()
        }
        if (e.response && e.response.data && e.response.data.message === 'User not found') {
          setShow(true)
        }
      })
  }

  useEffect(() => {
    window && ((window as any).onSigned = getUserInfo)
    getUserInfo()
  }, [wallet?.address])

  const confirm = () => {
    void axios
      .post(
        'https://api.matchfinance.io/users/disclaimer',
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem(`token-${wallet?.address}`)}` },
        },
      )
      .then(({ data }) => {
        // console.log(data)
        setShow(false)
      })
  }
  return show ? (
    <GlobalMask>
      <Box
        sx={{
          width: '500px',
          // height: '198px',
          borderRadius: '20px',
          overflow: 'hidden',
          '@media screen and (max-width: 500px)': {
            transform: 'scale(0.8)',
            minWidth: '540px',
          },
        }}
      >
        <Box
          sx={{
            height: '235px',
            position: 'relative',
            background: '#5065E4',
            overflow: 'hidden',
          }}
        >
          <Image src="/static/img/privacy_policy.svg" />
        </Box>
        <Box sx={{ padding: '25px 24px 17px', background: '#fff' }}>
          <Text
            sx={{
              color: '#17344F',
              fontSize: '20px',
              fontWeight: 700,
              lineHeight: '135%',
              paddingBottom: '25px',
              borderBottom: '1px solid #EAEAEA',
              textAlign: 'center',
            }}
          >
            Privacy policy
          </Text>
          <Box
            sx={{
              mt: '25px',
              height: '245px',
              overflowY: 'auto',
              color: '#17344F',
              fontSize: '16px',
              lineHeight: '24px',
              fontFamily: 'Poppins',
              fontWeight: 400,
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#EAEAEA',
              },
              '&::-webkit-scrollbar': {
                width: '2px',
                height: '2px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#8EA2F2',
                borderRadius: '15px',
              },
            }}
          >
            <Text>
              By entering this website, I confirm that I have read, understand and agree to be
              legally bound by the Disclaimer and the Terms and Conditions for Match Finance, as
              well as any presentation deck or other documentation published in relation to the
              foregoing, including all future amendments thereto. This confirmation is irrevocable
              and will apply to all of my uses of the website or the Match Finance
              protocol/application without requiring me to separately confirmation in each specific
              instance.
              {!showMore && (
                <span
                  style={{
                    color: '#5065E4',
                    textDecorationLine: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => setShowMore(true)}
                >
                  Learn More
                </span>
              )}
            </Text>
            {showMore && (
              <>
                <Text mt="15px">
                  I understand that there are numerous risks associated with my access or
                  interaction with the website, the Match Finance protocol/application, $MATCH token
                  or other digital assets, some of which are listed in the foregoing documents. By
                  clicking “Confirm” below, I expressly acknowledge, accept and assume these risks.
                </Text>
                <Text mt="15px">
                  I acknowledge and agree that the website and the Match Finance
                  protocol/application comprise a technology platform which solely provides
                  information about data on the applicable blockchains regarding my interactions
                  with $MATCH token or other digital assets. I accept that Furion Limited and its
                  affiliates or their officers, employees or principals have no custody over my
                  funds or digital assets, or any ability or duty to transact on my behalf or power
                  to reverse my transactions. Furion Limited its affiliates and their officers,
                  employees or principals do not endorse or provide any warranty with respect to any
                  digital asset.
                </Text>
                <Text mt="15px">
                  I acknowledge and agree that $MATCH token are not intended to constitute
                  securities, units in a collective investment scheme or business trust.
                  Accordingly, the foregoing documents do not, and is not intended to, constitute a
                  prospectus, profile statement, or offer document of any sort, and should not be
                  construed as an offer of securities of any form, units in a business trust, units
                  in a collective investment scheme or any other form of investment, or a
                  solicitation for any form of investment in any jurisdiction.
                </Text>
                <Text mt="15px">
                  I hereby confirm that I am not a resident or citizen of the United States of
                  America or any of the Restricted Territories (full list as set out in the Terms of
                  Use) where access or interaction with the website, the Match Finance
                  protocol/application, $MATCH token or other digital assets would be prohibited or
                  restricted in any manner.
                </Text>
                <Text mt="15px">
                  To the fullest extent permissible under applicable laws, I hereby irrevocably and
                  unconditionally release and waive all my claims (or all claims on behalf of the
                  legal entity which I represent) against Furion Limited and its affiliates as well
                  as their officers, employees or principals relating to the Match Finance
                  protocol/application, $MATCH token or other digital assets, whether such claims
                  are known or unknown at this time, and regardless of how they arise.
                </Text>
                <Text mt="15px">
                  RISK FACTORS: You acknowledge and agree that the Match Finance
                  protocol/application are currently in the initial development stages and there are
                  a variety of unforeseeable risks with utilising the Match Finance
                  protocol/application. In the worst scenario, this could lead to the loss of all or
                  part of your digital assets interacting with the Match Finance
                  protocol/application. IF YOU DECIDE TO UTILISE THE MATCH FINANCE
                  PROTOCOL/APPLICATION YOU EXPRESSLY ACKNOWLEDGE, ACCEPT AND ASSUME ALL RISKS IN
                  CONNECTION THEREWITH, SOME OF WHICH ARE SUMMARISED BELOW:
                </Text>
                <Text mt="7px">
                  - Use of the Match Finance protocol/application and interaction with Match Pools
                  carry financial risk. You acknowledge and agree that you are aware of such risks,
                  in particular transactions relating to digital assets are very risky, and such
                  digital assets are, by their nature, highly experimental, risky, volatile and
                  generally irreversible. You should not make any transactional decision without
                  first conducting your own research. You are solely and exclusively responsible for
                  determining whether any Match Pool, any transaction, or strategy, or any other
                  product or service in connection with the same is appropriate or suitable for you
                  based on your own objectives and personal and financial situation. You acknowledge
                  and agree that you will access and use the Match Finance protocol/application and
                  interact with Match Pools at your own risk.
                </Text>
                <Text mt="7px">
                  - The prices of blockchain assets are extremely volatile. Fluctuations in the
                  price of other digital assets could materially and adversely affect the value of
                  your digital assets held in Match Pools, which may also be subject to significant
                  price volatility. We cannot guarantee that any users interacting with Match Pools
                  will not lose money.
                </Text>
                <Text mt="7px">
                  - Neither the Match Finance protocol/application or Match Pools hold in custody,
                  store, send, or receive any of your digital assets. This is because your digital
                  assets exist only by virtue of the ownership record maintained on the relevant
                  Blockchain Network. Any transfer of digital assets occurs within the relevant
                  Blockchain Network, and not on the Match Finance protocol/application or Match
                  Pools.
                </Text>
                <Text mt="7px">
                  - The smart contracts underlying the Match Finance protocol/application may
                  contain security vulnerabilities, errors, failures, bugs or economic loopholes
                  which may be exploited by third parties, causing you to suffer losses. Interaction
                  with these smart contracts are entirely at your own responsibility and liability,
                  and Furion Limited is not a party to the smart contracts. Further, when you
                  interact with Match Pools and place digital assets therein, these digital assets
                  may be re-deployed with the underlying Lybra Finance protocol. The development
                  team for the underlying Lybra Finance protocol is independent from Furion Limited,
                  so Furion Limited has no control over the underlying Lybra Finance protocol or the
                  underlying smart contracts. These smart contracts deployed by the underlying Lybra
                  Finance protocol may contain security vulnerabilities, errors, failures, bugs or
                  economic loopholes which may be exploited by other third parties, causing you to
                  suffer losses in connection with any digital assets re-deployed by Match Pools.
                  Furion Limited cannot be responsibility for any security vulnerabilities, errors,
                  failures, bugs or economic loopholes in respect of the underlying Lybra Finance
                  protocol or the underlying smart contracts.
                </Text>
                <Text mt="7px">
                  - No manager/creator of Match Pools, nor will Furion Limited be able to guarantee
                  the future performance of a Match Pool, any specific level of performance, the
                  success of any strategy or your overall results from interacting with Match Pools.
                  When reviewing the information, portfolio, performance, opinions of these
                  managers, do not assume that such manager is unbiased, independent or qualified to
                  provide financial information or opinions. Past performance and risk scores have
                  many inherent limitations and are not indicative of future results. No
                  representation or guarantee is being made that any user of Match Pools will or is
                  likely to achieve gains or losses similar to the past performance. The actual
                  percentage gains or losses experienced by users will vary depending on many
                  factors.
                </Text>
                <Text mt="7px">
                  - Hackers or other malicious groups or organisations may attempt to interfere with
                  the Match Finance protocol/application or Match Pools in a variety of ways,
                  including, but not limited to, malware attacks, denial of service attacks,
                  consensus-based attacks, Sybil attacks, smurfing and spoofing, which may result in
                  losses incurred by you.
                </Text>
              </>
            )}
          </Box>

          <Flex sx={{ alignItems: 'center', mt: '22px' }}>
            <Image
              src={`/static/icons/${agreed ? 'checked' : 'unchecked'}.svg`}
              sx={{ cursor: 'pointer' }}
              onClick={() => setAgreed(!agreed)}
            />
            <Text
              sx={{
                ml: '3px',
                fontSize: '12px',
                color: 'rgba(23, 52, 79, 0.5)',
              }}
            >
              *I agree with the{' '}
              <span style={{ color: 'rgba(23, 52, 79, 0.8)' }}>Privacy policy</span>
            </Text>
          </Flex>
          <Flex sx={{ flexDirection: 'column', alignItems: 'center', mt: '25px' }}>
            <Button
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#5065E4',
                width: '168px',
              }}
              disabled={!agreed}
              onClick={confirm}
            >
              Confirm
            </Button>
            <Button
              variant="textual"
              sx={{ width: '80%' }}
              onClick={disconnect}
            >
              Disconnect
            </Button>
          </Flex>
        </Box>
      </Box>
    </GlobalMask>
  ) : null
}
